import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation, NavLink } from 'react-router-dom';
import '../styles/main.css';
import SubliSnap from '../subli-snap-header.svg';

const Navigation = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const shouldAppearScrolled = ((location.pathname === '/' || location.pathname === '/product-page/sublimation-spray') && location.pathname !== '/contact' && location.pathname !== '/about-us') && isScrolled;

    const handleNavClick = () => {
        setIsNavbarExpanded(false);
    };

    return (
        <div className='bg-color-nav'>
            <Navbar
                expand="lg"
                className={`bg-body-tertiary eql-font nav-container ${shouldAppearScrolled ? 'scrolled' : ''}`}
                expanded={isNavbarExpanded}
                onToggle={() => setIsNavbarExpanded(!isNavbarExpanded)}
            >
                <section>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" aria-label="Toggle navigation" className={`${isNavbarExpanded ? 'd-none' : ''}`} />
                    <Navbar.Collapse className='nav-section' id="basic-navbar-nav">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <Nav className="me-auto navbar-nav">
                                <span className='close-menu' onClick={handleNavClick}>X</span>
                                <div className='nav-link-wrapper'>
                                    <NavLink to='/' className='nav-font nav-link mr-2rem' aria-label='Go to Home Page' onClick={handleNavClick}>Home</NavLink>
                                    <NavLink to='/about-us' className='nav-font nav-link mr-2rem' aria-label='Go to About page' onClick={handleNavClick}>About</NavLink>
                                    <NavLink to='/contact' className='nav-font nav-link mr-2rem' aria-label='Go to Contact Page' onClick={handleNavClick}>Contact</NavLink>
                                    <NavLink to='/specials' className='nav-font nav-link mr-2rem' aria-label='Go to Specials Page' onClick={handleNavClick}>SPECIALS</NavLink>
                                    <NavLink style={{display:'none'}} to='/dtf-transfers' className='nav-font nav-link mr-2rem' aria-label='Go to DTF transfer page' onClick={handleNavClick}>DTF Transfers</NavLink>
                                    <a href="https://blog.sublisnap.com" className='nav-font nav-link mr-2rem' target="_self" aria-label="Read our blog" onClick={handleNavClick} hidden>Blog</a>
                                </div>
                                <div className='primary-btn-container'>
                                    <Link to='/instructions' aria-label='instructions page' className="order-now-btn" onClick={handleNavClick}>Instructions</Link>
                                    <Link to='/product-page/sublimation-spray' aria-label='product page' className="order-now-btn" onClick={handleNavClick}>Shop</Link>
                                </div>
                            </Nav>

                        </div>


                    </Navbar.Collapse>
                </section>
            </Navbar>
            <div className={`container ${shouldAppearScrolled ? 'scrolled' : 'bg-color-nav subli-snap-container'}`}>
                <img className='subli-snap-logo' src={SubliSnap} alt='sublisnap' />
            </div>
        </div>
    );
}

export default Navigation;
