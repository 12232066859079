import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <div className="bg-color-footer">
            <footer>
                <ul className="footer-nav">
                    <li><Link className='nav-link' to="/affiliate-page">Affiliate</Link></li>
                    <li><Link className='nav-link' to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link className='nav-link' to="/shipping-policy">Shipping Policy</Link></li>
                    <li><Link className='nav-link' to="/faq">FAQ</Link></li>
                </ul>
                <p className="text-center text-body-secondary">© 2024 SubliSnap!</p>
            </footer>
        </div>
    )
}

export default Footer