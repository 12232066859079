import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CheckoutRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const productId = urlParams.get('id');
        if (productId) {
            // Redirect to the Stripe payment link
            window.location.href = 'https://buy.stripe.com/eVa7vxe8Pe9R6NqcMM';
        } else {
            // Handle missing product ID case
            document.body.innerHTML = 'Product ID is missing.';
        }
    }, [location]);

    return (
        <div>
            <p>Redirecting to checkout...</p>
        </div>
    );
};

export default CheckoutRedirect;
