import React from 'react'
import Blob from '../img/blob1.webp';
import Blob2 from '../img/blob2.webp';

const Banner = () => {
    return (
        <section>
            <div id='about-section' className='about-container mt-large'>
                <div className='about-text-wrapper'>
                    <h2>Unlike DTF powder for sublimation</h2>
                    <h3 className='about-text eql-font'>WE ONLY USE THE BEST INGREDIENTS</h3>
                    <span>
                        At SubliSnap, we're committed to revolutionizing the way you think about fabric printing. Our specially formulated spray is engineered to create a permanent bond between sublimation ink and cotton fabrics, offering unparalleled durability and quality.
                    </span>
                </div>
                <div className='about-img-wrapper'>

                    <img className='about-img animate-bounce' src={Blob} alt='pile of powder crossed out' loading='lazy' />


                    <img className='about-img-2 animate-bounce' src={Blob2} alt='no powder used in ingrediants' loading='lazy' />

                </div>
            </div>
        </section>
    )
}

export default Banner