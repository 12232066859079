/* global gtag */
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const sendPageView = () => {
      gtag('event', 'conversion', {
        'send_to': 'AW-11112049019/MNV-CO_Ss5AYEPvS0bIp',
        'value': 0.0,
        'currency': 'USD'
      });
    };

    sendPageView();
  }, [location]);

  return null;
};

export default PageViewTracker;
