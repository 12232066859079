import React from 'react'

const Instructions = () => {
  return (
    <section className='instructions-section'>

      <div className='prep-step'>
        <div className='pre-press'>
          <h2>Prep Process Tips</h2>
          <p>
            Prime the spray bottle by spraying the solution into the sink.
            This should remove air from the tube, reducing the risk of dripping.
            Once the bottle is primed, you should see a fine mist start to form.
            Let us know if you have any issues <a href='https://sublisnap.com/contact' aria-label='enter contact page'>here</a>.
          </p>

          <div className='tips-container'>
            <h2>Tips for Best Results</h2>
            <li>Ensure the fabric is completely dry before pressing</li>
            <li>Use protective gloves if you have sensitive skin</li>
            <li>Experiment with different heat press settings, as results can vary based on the fabric and equipment used</li>
            <li>Practice on a scrap piece of fabric if you're new to sublimation printing</li>
            <li>We read through countless reviews on other sublimation
              sprays to learn what the common issues where.
              We aim to solve those issues with this product.
              With that said sometimes communication is the issue and not the product itself.
              We are here to help. Either reach out to us directly, or show your results in the comment section.

            </li>
          </div>
          <div className='spray-bottle-care'>
            <h2>Spray Bottle Care</h2>
            <p>It is best to rinse out your sprayer head after each use.
              When you are finished using the sprayer,
              simply fill a glass with water and set your sprayer tube inside while pulling the trigger.
              This only takes a few pumps to flush the sublimation spray fluid out of the head.
              Once finished, pull the sprayer out of the water and finish spraying the rest of the water out.
              You can now place the sprayer back in the bottle.
            </p>
            <p><strong>If your sprayer is jammed</strong> soak the head in nearly boiling water for 5 minutes. After soaking proceed to spray the hot water through the sprayer until you see a fine mist.
              Essentially, if the sprayer is not rinsed out between uses you risk build up of fluid to dry inside the sprayer head. This can cause the stream to be more fine which will ruin your shirt.<strong> It is always best to test the sprayer before use.</strong>
            </p>
          </div>

        </div>
        <div className='youtube-video'>
          <iframe width='350px' height='200px' src="https://www.youtube.com/embed/xKnXlZ2Va4U?si=2_dUF_Z4CZzYwRZR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </div>

        <div className='materials'>

          <h2>Materials Needed</h2>
          <ul>
            <li>SubliSnap spray</li>
            <li>Cotton fabric (t-shirts, bags, etc.)</li>
            <li>Sublimation printer and sublimation ink</li>
            <li>Sublimation paper</li>
            <li>Heat press machine</li>
            <li>Lint roller</li>
            <li>Heat-resistant tape</li>
            <li>Protective gloves (optional)</li>
          </ul>
        </div>


      </div>
      <div className='how-to-container'>
        <h1>How to Sublimate on Cotton Shirts</h1>

        <div className='heat-settings'>
          <h2>Heat Settings</h2>
          <p>Tempature: 375F</p>
          <p>Time: 45 Seconds</p>
        </div>

        <div className='instructions-container'>
          <ul>
            <li>For best results, lint roll the area where you plan to apply the design.</li>
            <li>Unlock the sprayer by pressing the tab on the side.</li>
            <li>Prime the sprayer by spraying out excess air in the tube until you have a fine mist.</li>
            <li>Angle the sprayer about 45 degrees. Spray the entire area where you plan to sublimate the design on.</li>
            <li>The area should feel wet but not soaked.</li>
            <li>Let air dry or carefully dry under the heat press.<br></br> <strong>Note:</strong> You can scorch the fabric if it is wet while pressing for longer than a few seconds at a time.</li>
            <li>Cold peel design.</li>
            <li>Wash shirt on cold setting to remove the slight starchy feel of the shirt. <br></br>
              <strong>Note:</strong> Do not use detergent or fabric softener on your first wash.
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Instructions