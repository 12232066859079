import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Checkmark from '../img/check-mark.png';

const DTF = () => {
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null); // Holds the image file
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dpi = 300;
  const pricePerSquareInch = 0.075;

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImageFile(file); // Save the actual file for form submission
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const pixelWidth = img.width;
      const pixelHeight = img.height;
      const widthInInches = pixelWidth / dpi;
      const heightInInches = pixelHeight / dpi;
      setOriginalWidth(widthInInches);
      setOriginalHeight(heightInInches);
      setWidth(widthInInches.toFixed(2));
      setHeight(heightInInches.toFixed(2));
      setImage(img.src);
    };
  }, [dpi]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const aspectRatio = originalWidth / originalHeight;

  const handleWidthChange = (e) => {
    const newWidth = parseFloat(e.target.value) || '';
    setWidth(newWidth);
    if (newWidth) setHeight((newWidth / aspectRatio).toFixed(2));
  };

  const handleHeightChange = (e) => {
    const newHeight = parseFloat(e.target.value) || '';
    setHeight(newHeight);
    if (newHeight) setWidth((newHeight * aspectRatio).toFixed(2));
  };

  const totalPrice = (width && height) ? (parseFloat(width) * parseFloat(height) * pricePerSquareInch).toFixed(2) : '0.00';

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsSubmitted(false);
    setName('');
    setEmail('');
    setNotes('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('notes', notes);
    formData.append('width', width);
    formData.append('height', height);
    formData.append('totalPrice', totalPrice);
  
    // Check if the image file exists and append it to FormData
    if (imageFile) {
      formData.append('attachment', imageFile);  // Append the image file directly
      formData.append('attachmentType', imageFile.type);
      formData.append('attachmentName', imageFile.name);
  
      // Send data using FormData
      fetch('https://script.google.com/macros/s/AKfycbwVxvD3MtGQxzTYdDa0d3O70ybABwjcODLsW6-gh2v6AcCMDoIJWnDIUb7jY2EO2g2m/exec', {
        redirect: "follow",
        method: "POST",
        body: formData,  // Send the FormData directly
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('Google Apps Script response:', data);
        if (data.status === 'success') {
          setIsSubmitted(true);
        } else {
          alert('Oops! There was a problem sending data to Google Sheets and Drive');
        }
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
        alert('Oops! There was a problem sending data to Google Sheets and Drive');
      });
    } else {
      console.log('No image file to append');
    }
  };
  

  return (
    <section className="dtf-section-container">
      <div className="flex-container">
        {/* Checklist Section */}
        <div className="checklist">
          <h2>Design Checklist</h2> 
          <div className="checklist-item">
            <img src={Checkmark} alt="Check mark" className="check-mark" />
            <span>Ensure your image is in high resolution (300 DPI).</span>
          </div>
          <div className="checklist-item">
            <img src={Checkmark} alt="Check mark" className="check-mark" />
            <span>Accepted formats: JPEG, PNG, SVG.</span>
          </div>
          <div className="checklist-item">
            <img src={Checkmark} alt="Check mark" className="check-mark" />
            <span><strong>Do not</strong> mirror your image.</span>
          </div>
          <div className="checklist-item">
            <img src={Checkmark} alt="Check mark" className="check-mark" />
            <span>Maximum file size: 10 MB.</span>
          </div>
          <div className="checklist-item">
            <img src={Checkmark} alt="Check mark" className="check-mark" />
            <span>Ensure the background is transparent if needed.</span>
          </div>
        </div>

        {/* Image Upload Section */}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {image ? (
            <img src={image} alt="Uploaded Artwork" className="uploaded-image" />
          ) : (
            <p>Drag & drop an image here, or click to select an image</p>
          )}
        </div>
      </div>

      {/* Modal */}
      <div className="artwork-dimensions">
        <div className="artwork-dimensions__form-number-row">
          <div className="artwork-dimensions__form-number-group">
            <label className="artwork-dimensions__number-label">Width (inches)</label>
            <div className="artwork-dimensions__number-control">
              <input
                className="artwork-dimensions__number-field"
                type="number"
                name="width"
                value={width}
                onChange={handleWidthChange}
                step="0.01"
                min="0.1"
                max={originalWidth.toFixed(2)}
                disabled={!image}
              />
            </div>
          </div>
          <div className="artwork-dimensions__multiplier">
            <span className="artwork-dimensions__multiplier">×</span>
          </div>
          <div className="artwork-dimensions__form-number-group">
            <label className="artwork-dimensions__number-label">Height (inches)</label>
            <div className="artwork-dimensions__number-control">
              <input
                className="artwork-dimensions__number-field"
                type="number"
                name="height"
                value={height}
                onChange={handleHeightChange}
                step="0.01"
                min="0.1"
                max={originalHeight.toFixed(2)}
                disabled={!image}
              />
            </div>
          </div>
        </div>
        <div className="artwork-dimensions__price-row">
          <div className="artwork-dimensions__form-number-group artwork-dimensions__form-number-group--price">
            <div className="artwork-dimensions__number-label">Price / in²</div>
            <div className="artwork-dimensions__static-input">${pricePerSquareInch}</div>
          </div>
          <div className="artwork-dimensions__equal-sign">=</div>
          <div className="artwork-dimensions__form-number-group artwork-dimensions__form-number-group--price">
            <div className="artwork-dimensions__number-label">Total price</div>
            <div className="artwork-dimensions__static-input">${totalPrice}</div>
          </div>
        </div>
      </div>

      <button onClick={openModal} disabled={!image} className="submit-button">
        Submit for Digital Proof
      </button>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>X</button>
            <div className="progress-indicator">
              <span className={`circle ${!isSubmitted ? 'active' : ''}`}></span>
              <span className={`circle ${isSubmitted ? 'active' : ''}`}></span>
            </div>
            {!isSubmitted ? (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <h2>Review Your Order</h2>
                <img src={image} alt="Artwork" className="modal-image" />
                <p>Quote: ${totalPrice}</p>
                <p>Size: {width} × {height} inches</p>

                {/* Hidden inputs to capture the size and total price */}
                <input type="hidden" name="image_width" value={width} />
                <input type="hidden" name="image_height" value={height} />
                <input type="hidden" name="total_price" value={totalPrice} />

                {/* This input is to include the image file in the form submission */}
                <input type="file" name="attachment" accept="image/png, image/jpeg" style={{ display: 'none' }} />

                <div className="user-info">
                  <div className="user-wrapper">
                    <div className="sudo-border">
                      <div className="user-info__field">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={name}
                          className="input-form"
                          placeholder="Full name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="sudo-border">
                      <div className="user-info__field">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="input-form"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="user-info__field">
                    <textarea
                      id="notes"
                      name="notes"
                      className="note-form"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Any additional instructions?"
                    />
                  </div>
                </div>
                <button className="submit-button" type="submit">Submit Proof</button>
              </form>
            ) : (
              <div className="thank-you-message">
                <h2>Thank you!</h2>
                <p>We will review your order and send you an invoice.</p>
                <button onClick={closeModal}>Close</button>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default DTF;
