import React from 'react'
import { Card } from 'react-bootstrap';
import SubliSnapProduct from '../img/subli-snap-right.png'
import BoxBottle from '../img/box-bottle.png'

const gtag_report_conversion = (url) => {
    const callback = () => {
        window.location.href = url;
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-11112049019/F4mFCKmbhsMZEPvS0bIp',
        'event_callback': callback
    });
    return false;
};

const ProductCards = () => {
    const handleBuyNowClick = (e, url) => {
        e.preventDefault();
        gtag_report_conversion(url);
    };

    return (
        <section className='card-container product-card-mt-large eql-font'>
            <Card style={{ borderBottom: 'none' }}>
                <img className='blue-bg product-card-img' alt='step one - spray' src={SubliSnapProduct} loading='lazy' />
                <span className='product-card-txt'>SUBLISNAP SPRAY</span>
                <div className='btn-call-to-action-container'>
                    <a className='call-to-btn' href='https://sublisnap.com/product-page/sublimation-spray'>VIEW PRODUCT</a>

                    <a
                        href="https://buy.stripe.com/eVa7vxe8Pe9R6NqcMM"
                        className='call-to-btn'
                        aria-label='a button to purchase product'
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => handleBuyNowClick(e, 'https://buy.stripe.com/eVa7vxe8Pe9R6NqcMM')}
                    >
                        <span>BUY NOW</span>
                    </a>
                </div>
            </Card>
            <Card style={{ borderBottom: 'none' }}>
                <img className='orange-bg product-card-img' alt='step two - spray' src={BoxBottle} loading='lazy' />
                <span className='product-card-txt'>SUBLISNAP CARDBOARD COATING</span>
                <div className='btn-call-to-action-container'>
                    <a className='call-to-btn' href='https://custom-shipping-boxes.com/'>LEARN MORE</a>

                    <a
                        href="https://buy.stripe.com/5kAeXZ7KrfdV4Fi5kl"
                        className='call-to-btn'
                        aria-label='a button to purchase product'
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => handleBuyNowClick(e, 'https://buy.stripe.com/5kAeXZ7KrfdV4Fi5kl')}
                    >
                        <span>BUY NOW</span>
                    </a>
                </div>
            </Card>
        </section>
    );
};

export default ProductCards;
